import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import MainBanner from "../components/DataScienceOnlineCourses/MainBanner"
import FeaturedServices from "../components/DataScienceOnlineCourses/FeaturedServices"
import AboutUs from "../components/DataScienceOnlineCourses/AboutUs"
import OurMission from "../components/DataScienceOnlineCourses/OurMission"
import Courses from "../components/DataScienceOnlineCourses/Courses"
import client from "../utils/apollo-client"
import { gql, useMutation, useQuery, ApolloProvider } from "@apollo/client"
import { useIntl } from "react-intl"

//generate query for courses
const GET_COURSES = gql`
  query GetCourses($locale: I18NLocaleCode) {
    courses(locale: $locale) {
      data {
        id
        attributes {
          title
          slug
          description
          certificate
          available
          who
          instructor {
            data {
              attributes {
                firstname
                lastname
                email
                phonenumber
                picture {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          duration
          price
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`

const Index = () => {
  const intl = useIntl()
  const { loading, error, data } = useQuery(GET_COURSES, {
    client: client,
    variables: {
      locale: intl.locale,
    },
  })

  return (
    <ApolloProvider client={client}>
      <Layout>
        <Navbar />
        <MainBanner />
        <FeaturedServices />
        <AboutUs />
        <OurMission />
        {loading && <p>Loading ...</p>}
        <div className="courses-area ptb-100 bg-fafafb">
          <div className="container">
            <div className="section-title">
              <h2>{intl.formatMessage({ id: "courseslisttitle" })} </h2>
              <p>{intl.formatMessage({ id: "courseslistcaption" })} </p>
            </div>
            <div className="row">
              {!loading &&
                data?.courses?.data?.map((element: any) => {
                  return <Courses course={element} key={element.id}/>
                })}
            </div>
          </div>
        </div>
        <Footer />
      </Layout>
    </ApolloProvider>
  )
}

export default Index
